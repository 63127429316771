import './App.css';

import Trackers from "./data";

function App() {
  return (
    <div className="App">
      <Trackers />
    </div>   
  );
}
export default App;
